import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../../components/Layout';
import Checkmark from '../../../components/atoms/Checkmark';
import CFS from '../../../components/molecules/CFS';
import { INSCRIPCION_ROUTE } from '../../../constants/routes';

import imgR from '../../../assets/images/pages/requisitos-de-acceso/imgR.png';

import '../../../assets/styles/pages/requisitos-de-acceso.scss';

const RequisitoDeAcceso = () => (
  <Layout
    title="Requisitos de Acceso | ELE Barcelona"
    description="Información de las condiciones para ser apto en nuestros cursos. Actitudes y capacidades orientadas a los idiomas y la enseñanza. Objetivos profesionales."
  >
    <div className="requisitos-de-acceso">
      <CFS title="Requisitos de acceso" />
      <section className="second-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="c-title-34">Para ser apto en nuestro curso debes:</h3>
              <Checkmark>Ser mayor de 18 años.</Checkmark>
              <Checkmark>
                Mostrar habilidades para expresarse correctamente en español de forma oral y
                escrita. No hace falta que seas nativo y, si no lo eres, deberás demostrar un nivel
                C1 de español.
              </Checkmark>
              <Checkmark>Mostrar entusiasmo por el aprendizaje y la enseñanza.</Checkmark>
              <Checkmark>
                Mostrar las aptitudes necesarias para realizar las tareas del curso – nos puedes
                facilitar el título de la carrera o la nota de la selectividad. Si no tienes
                estudios universitarios no te preocupes, también valoramos la experiencia laboral.
              </Checkmark>
              <Checkmark>
                Tener buena salud y ser capaz de seguir el ritmo físico que conlleva dar clases cada
                día.
              </Checkmark>
              <Checkmark>
                Tener una personalidad adecuada para la enseñanza: ser una persona amistosa, con
                seguridad, con buena imagen y tener don de gentes.
              </Checkmark>
              <p className="second-section__description c-text-15 c-text-15--gray-100">
                Para poder ser aceptado/a en el curso debes pasar una entrevista (de 30 minutos) con
                uno de nuestros coordinadores del curso con el objetivo de conocerte, responder a
                tus dudas, explicarte lo que hacemos y cómo lo hacemos, qué te gustaría conseguir
                con el curso y cuáles son tus planes para el futuro.
              </p>
              <Link to={INSCRIPCION_ROUTE} className="c-btn c-btn--green">
                Inscríbete ahora
              </Link>
            </div>
            <div className="col-md-6">
              <img className="img-r" src={imgR} alt="imgR" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default RequisitoDeAcceso;
